@font-face {
  font-family: 'Roboto Condensed';
  src:  url('../fonts/RobotoCondensed-Regular.woff2') format('woff2'), url('../fonts/RobotoCondensed-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Condensed';
  src:  url('../fonts/RobotoCondensed-Bold.woff2') format('woff2'), url('../fonts/RobotoCondensed-Bold.woff') format('woff');
  font-weight: bold;
}

$roboto: 'Roboto Condensed', sans-serif;

body {
  font-size: 1.6rem;
  font-family: $roboto;
  line-height: 1.2;
}

h1, h2, h3, h4 {
  font-weight: bold;
  line-height: inherit;
}

h1 {
  font-size: 5.3rem;
  font-size: 3.8vw;
  line-height: 5.7rem;
  line-height: 4.5vw;
  margin: 2rem 0;

  @include MQ(XLplus) {
    font-size: 5.3rem;
    line-height: 5.7rem;
  }

  @include MQ(Mneg) {
    font-size: 3rem;
    line-height: 3.3rem;
  }
}

h2 {
  font-size: 5.2rem;
  font-size: 4.5vw;

  @include MQ(XLplus) {
    font-size: 5.2rem;
  }

  @include MQ(Mneg) {
    font-size: 2.9rem;
  }
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 30px;
}

p {
  font-size: 2.3rem;
  margin: 2rem 0;

  @include MQ(Mneg) {
    font-size: 1.6rem;
  }
}

