section.indications {
    background-color: $white;
    position: relative;
    margin-bottom: 30px;

    .inner {

        .icon-container {

            .show-icon {
                opacity: 0;
                transition: 1s opacity ease, 1s transform ease;
                transform: translateY(-20px);

                &.animation {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        img {
            max-width: 172px;
            padding-bottom: 30px;

            @include MQ(Mneg) {
                padding-bottom: 0;
            }

            &.polekowe {
                @include MQ(Mneg) {
                    @include span(5 of 12);
                }
            }
        }

        h2,h4 {
            text-align: center;
            color: $yellow;
        }

        h4 {
            font-size: 	2.7rem;

            @include MQ(Mneg) {
                font-size: 2rem;
                text-align: left;
                @include span(6 of 12);
                float: right;
                margin-top: 20px;
            }

            @include MQ(Sneg) {
                font-size: 1.7rem;
            }
        }

        .container {
            padding-left: span(2 of 12);
            padding-right: span(2 of 12);

            @include MQ(Mneg) {
                padding-left: span(1 of 12) + gutter(2.5);
                padding-right: span(1 of 12) + gutter(2.5);
            }

            margin: 0 auto;

            p {
                font-weight: bold;
                font-size: 1.8rem;

                @include MQ(Mneg) {
                    display: none;
                }
            }

            > p {
                text-align: center;

                @include MQ(Mneg) {
                    display: block;
                }
            }

            li {
                padding-bottom: 40px;
                padding-top: 40px;

                @include MQ(Mneg) {
                    padding-bottom: 15px;
                    padding-top: 15px;
                }

                figure {
                    margin: 0;
                    text-align: center;
                }

                figcaption {

                    p {
                        text-align: left;
                    }
                }

                &.two-column {

                    figure {

                        img {
                            max-width: 136px;

                            @include MQ(Mneg) {
                                @include span(5 of 12);
                            }
                        }
                    }
                }
            }

            &.cont-frame {
                padding-left: span(1 of 12) + gutter(2.5);
                padding-right: span(1 of 12) + gutter(2.5);

                .frame {
                    outline: 2px $yellow-border dashed;
                    // padding-top: $padding-indications;
                    // padding-bottom: $padding-indications;
                    padding: $padding-indications gutter(1.5);
                    margin-bottom: 40px;

                    .img-container {
                        margin: 0 auto;
                        max-width: 136px;

                        @include MQ(Mneg) {
                            @include span(5 of 12);
                        }
                    }

                    h4 {
                        padding-bottom: 6px;
                    }
                }
            }
        }
        &:nth-child(1) {
            padding-bottom: 0;
        }
        &:nth-child(2){
            padding-top: 0;
            padding-bottom: 0;
        }
        &:nth-child(3) {
            padding-top: 0;
        }
    }

    & *{
        // outline: 1px red dotted;
    }
}