.results {
    position: relative;
    display: none;
    pointer-events: none;

    @include MQ(Lneg) {
        display: none;
    }

    > .inner {
        padding: 70px 12vw 70px;
        border: 2px solid $yellow;
        border-top: none;
    }

    p {
        margin: 4rem 0;
    }

    table {
        width: 100%;
        table-layout: fixed;

        th, td {
            vertical-align: middle;
        }

        tr:first-child {

            th {

                span {
                    display: block;
                    text-align: center;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    color: $white;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &:nth-child(odd) {

                    span {
                        background-color: $yellow;
                    }
                }

                &:nth-child(even) {

                    span {
                        background-color: $crimson;
                    }
                }

                padding: 0 1.5vw;
            }
        }

        tr:nth-child(2) {

            th, td {
                padding-top: 20px;
                padding-bottom: 10px;
                text-align: center;
            }
        }

        tr:nth-child(n+3) {

            th {
                padding: 10px 0;
                text-align: center;
            }

            td {
                text-align: center;
                padding: 10px 0;
            }
        }
    }

    .table-wrapper {
        position: relative;
        padding-bottom: 20px;

        &:before, &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
        }

        &:before {
            bottom: -2px;
            background-color: $crimson;
        }

        &:after {
            bottom: -6px;
            background-color: $yellow;
        }
    }

    figure {
        margin: 40px 0;
    }

    .chart1 {
        margin-bottom: 60px;
    }


}