.action {

    @keyframes flipInY-right {
        from {
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            animation-timing-function: ease-in;
            opacity: 0;
        }

        40% {
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            animation-timing-function: ease-in;
        }

        60% {
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            opacity: 1;
        }

        80% {
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        }

        to {
            transform: perspective(400px);
            opacity: 1;
        }
    }

    @keyframes flipInY-left {
        from {
            transform: perspective(400px) rotate3d(0, 1, 0, -90deg);
            animation-timing-function: ease-in;
            opacity: 0;
        }

        40% {
            transform: perspective(400px) rotate3d(0, 1, 0, 20deg);
            animation-timing-function: ease-in;
        }

        60% {
            transform: perspective(400px) rotate3d(0, 1, 0, -10deg);
            opacity: 1;
        }

        80% {
            transform: perspective(400px) rotate3d(0, 1, 0, 5deg);
        }

        to {
            transform: perspective(400px);
            opacity: 1;
        }
    }


    > .inner {
        background-color: $crimson;
        position: relative;
        padding-bottom: 100px;

        p {
            color: $white;
        }

        .action-about {
            width: span(10);
            margin: 0 auto;

            h2 {
                color: $yellow;
                text-align: center;
            }

            p {
                text-align: center;
            }
        }

        .action-list {
            width: span(10);
            margin: 0 auto;
            padding-top: 115px;

            > ul {
                position: relative;
            }

            .plus {
                display: block;
            }

            @include MQ(Lplus) {
                height: 45vw;

                .window-wrapper {
                    opacity: 0;
                }

                .plus {
                    float: right;
                    position: relative;
                    width: 16.6vw;
                    height: 16.6vw;
                    border: 10px solid $yellow;
                    border-radius: 50%;
                    background-color: $crimson;
                    margin-top: -1.66vw;
                    margin-right: -7.9vw;
                    z-index: 10;

                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        width: 10.4vw;
                        height: 10px;
                        background-color: $yellow;
                    }

                    &:after {
                        transform: rotate(90deg);
                    }

                     &.animation{

                        .short-header {
                            transform: translateX(50px);
                            opacity: 0;
                        }

                        & + .window-wrapper {
                            -webkit-backface-visibility: visible !important;
                            backface-visibility: visible !important;
                            animation-name: flipInY-right;
                            animation-duration: 1s;
                            animation-fill-mode: forwards;
                        }
                    }

                    .short-header {
                        position: absolute;
                        right: calc(50% + 15px);
                        bottom: calc(50% + 15px);
                        text-align: right;
                        font-size: 2vw;
                        line-height: 1.9vw;
                        font-weight: bold;
                        color: $white;
                        transition: .2s transform ease-out, .2s opacity ease-out;
                    }
                }

                li {
                    position: absolute;

                    .window-wrapper {
                        background-color: $yellow;
                        width: 26vw;
                        padding: 1.6vw;

                        h3 {
                            font-size: 2vw;
                            line-height: 2vw;
                            color: $white;

                            span {
                                display: block;
                            }
                        }

                        p {
                            font-size: 1.6vw;
                            line-height: 1.8vw;
                            margin: 0.8vw 0 0;
                        }
                    }

                    &.one {
                        top: 0.4vw;
                        left: 0;

                        .shadow {
                            position: absolute;
                            right: -10px;
                            left: -10px;
                            top: -5.8vw;
                        }

                        //&:hover {
                        //
                        //    .short-header {
                        //        transform: translateX(-50px);
                        //    }
                        //}

                        //.plus {
                        //
                        //    &:hover {
                        //
                        //        & + .window-wrapper {
                        //            animation-name: flipInY-left;
                        //        }
                        //    }
                        //}
                    }

                    &.two {
                        top: -1.6vw;
                        right: 0;

                        .shadow {
                            position: absolute;
                            left: initial;
                            top: -7.9vw;
                            right: -8.3vw;
                            width: 108%;
                        }

                        .plus {
                            float: left;
                            width: 22.5vw;
                            height: 22.5vw;
                            margin-top: -4.6vw;
                            margin-right: auto;
                            margin-left: -15vw;

                            &:before, &:after {
                                width: 14.1vw;
                            }

                            .short-header {
                                position: absolute;
                                left: calc(50% + 15px);
                                bottom: calc(50% + 15px);
                                text-align: left;
                                font-size: 2vw;
                                line-height: 1.9vw;
                                font-weight: bold;
                                color: $white;
                            }
                        }

                        .window-wrapper {
                            width: 30vw;

                            h3, p {
                                text-align: right;

                                span {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    &.three {
                        top: 22.9vw;
                        right: auto;
                        left: 11.6vw;

                        .plus {
                            float: right;
                            width: 13.3vw;
                            height: 13.3vw;
                            margin-top: -6.25vw;
                            margin-right: -6.25vw;
                            margin-left: 0;

                            &:before, &:after {
                                width: 8.3vw;
                            }

                            //&:hover {
                            //
                            //    & + .window-wrapper {
                            //        animation-name: flipInY-left;
                            //    }
                            //}

                            .short-header {
                                position: absolute;
                                right: calc(50% + 15px);
                                top: calc(50% + 15px);
                                text-align: right;
                                font-size: 2vw;
                                line-height: 1.9vw;
                                font-weight: bold;
                                color: $white;
                            }

                            &:hover {

                                .short-header {
                                    transform: translateX(-50px);
                                }
                            }
                        }

                        .window-wrapper {
                            width: 24.1vw;

                            h3, p {
                                text-align: left;
                            }
                        }
                    }

                    &.four {
                        top: 17.5vw;
                        right: 0;

                        .shadow {
                            position: absolute;
                            left: initial;
                            top: 2.5vw;
                            right: -4.16vw;
                            width: 108%;
                        }

                        .plus {
                            float: left;
                            width: 15.8vw;
                            height: 15.8vw;
                            margin-top: -1.25vw;
                            margin-right: auto;
                            margin-left: -7.5vw;

                            &:before, &:after {
                                width: 10vw;
                            }

                            .short-header {
                                position: absolute;
                                left: calc(50% + 15px);
                                bottom: calc(50% + 15px);
                                text-align: left;
                                font-size: 2vw;
                                line-height: 1.9vw;
                                font-weight: bold;
                                color: $white;
                            }
                        }

                        .window-wrapper {
                            width: 25vw;

                            h3, p {
                                text-align: right;

                                span {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }

            @include MQ(Lneg) {
                padding-top: 40px;

                .plus {
                    position: absolute;
                    top: -2px;
                    left: -45px;
                    width: 30px;
                    height: 30px;
                    border: 5px solid $yellow;
                    border-radius: 50%;

                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        width: 14px;
                        height: 5px;
                        background-color: $yellow;
                    }

                    &:after {
                        transform: rotate(90deg);
                    }
                }

                ul {
                    padding-left: 45px;

                    li {
                        position: relative;
                    }
                }

                .short-header {
                    display: none;
                }

                .window-wrapper {

                    h3 {
                        font-size: 2.4rem;
                        line-height: 2.8rem;
                        color: $white;

                        span {
                            display: inline;
                        }
                    }

                    p {
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                        margin: 1.2rem 0 2.6rem
                    }
                }
            }


            @include MQ(XLplus) {
                height: 540px;

                .plus {
                    width: 200px;
                    height: 200px;
                    margin-top: -20px;
                    margin-right: -95px;

                    &:before, &:after {
                        width: 125px;
                    }

                    .short-header {
                        font-size: 2.3rem;
                        line-height: 2.2rem;
                    }
                }


                li {
                    position: absolute;

                    .plus {
                        display: block;
                    }

                    .window-wrapper {
                        background-color: $yellow;
                        width: 310px;
                        height: auto;
                        padding: 20px;

                        h3 {
                            font-size: 2.3rem;
                            line-height: 2.3rem;
                        }

                        p {
                            font-size: 1.8rem;
                            line-height: 2.2rem;
                            margin: 10px 0 0;
                        }
                    }

                    &.one {
                        top: 5px;

                        .shadow {
                            top: -70px;
                        }
                    }

                    &.two {
                        top: -10px;
                        right: 0;

                        .plus {
                            width: 270px;
                            height: 270px;
                            margin-top: -55px;
                            margin-right: 0;
                            margin-left: -180px;

                            &:before, &:after {
                                width: 170px;
                            }

                            .short-header {
                                font-size: 2.3rem;
                                line-height: 2.2rem;
                            }
                        }

                        .shadow {
                            position: absolute;
                            left: auto;
                            top: -95px;
                            right: -100px;
                        }

                        .window-wrapper {
                            width: 380px;
                        }
                    }

                    &.three {
                        top: 290px;
                        left: 140px;

                        .plus {
                            width: 160px;
                            height: 160px;
                            margin-top: -75px;
                            margin-right: -75px;

                            &:before, &:after {
                                width: 100px;
                            }

                            .short-header {
                                font-size: 2.3rem;
                                line-height: 2.2rem;
                            }
                        }

                        .window-wrapper {
                            width: 290px;
                        }
                    }

                    &.four {
                        top: 210px;
                        right: 0;

                        .plus {
                            width: 190px;
                            height: 190px;
                            margin-top: -15px;
                            margin-right: 0;
                            margin-left: -90px;

                            &:before, &:after {
                                width: 120px;
                            }

                            .short-header {
                                font-size: 2.3rem;
                                line-height: 2.2rem;
                            }
                        }

                        .shadow {
                            position: absolute;
                            left: auto;
                            top: 30px;
                            right: -50px;
                        }

                        .window-wrapper {
                            width: 300px;
                        }
                    }
                }
            }
        }

        .separator-container {
            background-color: $white;
            bottom: 0;

            .separator {
                border-top: 30px solid $crimson;
            }
        }
    }
}