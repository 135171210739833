section.ingredients {
    background-color: $white;
    position: relative;
    margin-bottom: 30px;

    .inner {
        background-color: $yellow;
        
        .container {
            overflow: hidden;
            margin: 0 auto;
            padding-left: span(1 of 12) + gutter(4);
            padding-right: span(1 of 12) + gutter(4);

            h2,h4 {
                text-align: center;
                color: $crimson;
            }
        
            h2 {
                padding-bottom: 40px;
            }
        
            h4 {
                font-size: 	2.7rem;
            }
        
            p {
                color: $white;
                text-align: center;
                margin-bottom: 30px;
            }
            
            .img-container {
                padding: $padding-el;
                position: relative;
                margin: 0 auto;
                max-width: 636px;

                @include MQ(Mneg) {
                    max-width: 278px;
                }

                .element {
                    display: inline-block;
                    width: 50%;

                    @include MQ(Mneg) {
                        width: 100%;
                    }

                    &.right {
                        padding-left: 40px;
                        right: -20%;
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        max-width: 298px;
                        // right: 0;
                        
                        &.animation {
                            animation-name: appear-right;
                            animation-duration: 1s; 
                            animation-timing-function: ease-out; 
                            animation-delay: 0s;
                            animation-iteration-count: 1;
                            animation-fill-mode: forwards;
                        }

                        @include MQ(Mneg) {
                            display: none;
                            padding-left: 0;
                        }
                    }
                    &.left {
                        padding-right: 40px;
                        left: -20%;
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        max-width: 298px;

                        &.animation {
                            animation-name: appear-left;
                            animation-duration: 1s; 
                            animation-timing-function: ease-out; 
                            animation-delay: 0s;
                            animation-iteration-count: 1;
                            animation-fill-mode: forwards;
                        }
                        
                        @include MQ(Mneg) {
                            padding-right: 0;
                            position: static;
                        }
                    }
                }

                .upper {
                    margin: auto;
                    z-index: 2;
                    position: relative;
                    opacity: 0;

                    &.animation {
                        animation-name: appear;
                        animation-duration: 1s; 
                        animation-timing-function: ease-out; 
                        animation-delay: 1s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                    }

                    @include MQ(Mneg) {
                        display: none;
                    }
                }

                &.unvisible {
                    max-width: 278px;
                    display: none;
                    float: none;
                    clear: both;

                    @include MQ(Mneg) {
                        display: block;
                    }

                    .element {
                        float: none;
                        clear: both;

                        &.right {

                            @include MQ(Mneg) {
                                position: static;
                                display: block;
                            }
                        }
                    }
                }
            }

            .two-column {
        
                ul {
                    padding-left: 23px;
            
                    li {
                        list-style: disc;
                        font-size: 2.3rem;
                        color: $crimson;

                        p {
                            color: $crimson;
                            text-align: left;
                            margin: 0;
                        }
                    }
                }
            }

            .btn-container {
                padding-top: 40px;

                .btn {
                    margin: 0 auto;
                }
            }
        }
        .separator {
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            width: 180px;
            margin: auto;
            border-left: 90px solid transparent;
            border-right: 90px solid transparent;
            border-top: 30px solid $yellow;
        }
    }
    & * {
        // outline: 1px red dotted;
    }


    @keyframes appear-left {
        0% {
            left: -20%;
        }
        100% {
            left: 0;
        }
    }

    @keyframes appear-right {
        0% {
            right: -20%;
        }
        100% {
            right: 0;
        }
    }

    @keyframes appear {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}