html {
  font-size: 62.5%;
}

body {
  margin: 0;
  min-width: 320px;
  overflow-x: hidden !important;
  font-size: 16px;
  font-size: 1.6rem;

  > section > .inner,
  > footer > .inner {
    padding-top: 70px;
    padding-bottom: 70px;

    @include MQ(Mneg) {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

figure {
  margin: 0;
}