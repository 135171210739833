section.effectiveness {
    background-color: $white;
    position: relative;
    // margin-bottom: 30px;

    .inner {

        .container {
            margin: 0 auto;
            text-align: center;

            h2 {
                text-align: center;
                color: $yellow;
            }
    
            > p {
                text-align: center;

                &.el-explain {
                    padding: 10px;
                    font-size: 1.5rem;
                    text-align: left;
                    margin: 0;

                    &:nth-child(1) {
                        padding-top: 35px;
                    }
                }
            }
    
            li {

                font-size: 2.3rem;

                span {
                    font-weight: bold;
                    color: $crimson;

                }

                &:nth-child(n+3) {

                    span {
                        color: $yellow;
                    }
                }

                &:nth-child(1) {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                @include MQ(Lneg) {
                    padding-bottom: 22px;
                }
                
            }

            .ba-element {
                display: inline-block;
                font-size: 2.8rem;
                vertical-align: middle;
                float: none;
                text-align: right;
                color: $yellow;
                font-weight: bold;
                
                &:first-child {
                    padding-right: gutter(5);
                    padding-left: 0;
                }

                &:last-child {
                    padding-left: gutter(5);
                    padding-right: 0;
                    text-align: left;
                    color: $crimson;
                }

                &.line {
                    border-bottom: none;
                    position: relative;
                    background: linear-gradient(to right, $yellow, $crimson);
                    height: 82px;
                }
                .arrow1, .arrow2 {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 30px 41px 0;
                    border-color: transparent #fff transparent transparent;
                }

                .arrow2 {
                    top: initial;
                    bottom: 0px;
                    border-width: 0 0 41px 30px;
                    border-color: transparent transparent #fff transparent;
                }

                img.arrow {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -80%;
                    opacity: 0;
                    right: 0;
                    margin: auto;
                    max-width: 219px;

                    &.animation {
                        animation-name: anim-left;
                        animation-duration: 0.7s; 
                        animation-timing-function: ease-out; 
                        animation-delay: 0s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                    }
                }

                @include MQ(Lneg) {
                    display: none;
                }
            }

            .btn-container {
                margin-top: 80px;
                position: relative;

                #border {
                    border-bottom: 2px $yellow solid;
                    position: absolute;
                    z-index: -1;
                    margin: auto;
                    top: 0;
                    bottom: 50%;
                }
                
                .btn {
                    margin: 0 auto;
                    z-index: 1200;
                    margin-bottom: -25px;
                    &.yellow {
                        background-color: $white;

                        &:hover {
                            background-color: $yellow;
                        }
                    }

                    @include MQ(Lneg) {
                        display: none;
                    }
                }
            }

            &.steps {
                padding-top: 30px;
                padding-bottom: 40px;
                width: 100%;

                @include MQ(SMneg) {
                    display: none;
                }
            }
        }

        &:nth-child(1) {
            padding-bottom: 0;
            text-align: center
        }
        &:nth-child(2) {
            padding-top: 0;

            @include MQ(Lplus) {
                padding: 0;
            }
        }

        .line-results {
            padding: 0;
            margin-bottom: -25px;

            @include MQ(Mplus) {
                width: 100%;
                margin: 0;
            }
        }
    }

    @keyframes anim-left {
        0% {
            left: -80%;
            opacity: 0;
        }
        100% {
            left: -30%;
            opacity: 1;
        }
    }
}

//media queries when: $M