@mixin clearfix() {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin reset-input() {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  appearance: none;
  &:focus {
    outline: 0;
  }
}

@mixin clear-outline {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style: none;
}

@mixin set-sprite($x, $y) {
  background: url('../img/sprite@2x.png') $x $y no-repeat;
  background-size: 1200px 700px;
}

@mixin set-placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @else if ($horizontal) {
    left: 50%;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  @else if ($vertical) {
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

@mixin relative-center() {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin cf {
  zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin cf-overflow {
  overflow: hidden;
  *overflow: visible;
  zoom: 1;
}

@mixin MQ($canvas) {

  @if $canvas==XS {
    @media only screen and (min-width: $XS) and (max-width: $S - 1) {
      @content;
    }
  }
  @else if $canvas==S {
    @media only screen and (min-width: $S) and (max-width: $M - 1) {
      @content;
    }
  }
  @else if $canvas==M {
    @media only screen and (min-width: $M) and (max-width: $L - 1) {
      @content;
    }
  }
  @else if $canvas==L {
    @media only screen and (min-width: $L) and (max-width: $XL - 1) {
      @content;
    }
  }
  @else if $canvas==XL {
    @media only screen and (min-width: $XL) and (max-width: $XXL - 1) {
      @content;
    }
  }

  @else if $canvas==XSplus {
    @media only screen and (min-width: $XS + 1) {
      @content;
    }
  }
  @else if $canvas==Splus {
    @media only screen and (min-width: $S + 1) {
      @content;
    }
  }

  @else if $canvas==SMplus {
    @media only screen and (min-width: $SM + 1) {
      @content;
    }
  }

  @else if $canvas==Mplus {
    @media only screen and (min-width: $M + 1) {
      @content;
    }
  }
  @else if $canvas==Lplus {
    @media only screen and (min-width: $L + 1) {
      @content;
    }
  }
  @else if $canvas==XLplus {
    @media only screen and (min-width: $XL + 1) {
      @content;
    }
  }

  @else if $canvas==QXLplus {
    @media only screen and (min-width: $QXL + 1) {
      @content;
    }
  }

  @else if $canvas==QSXLplus {
    @media only screen and (min-width: $QSXL + 1) {
      @content;
    }
  }

  @else if $canvas==XXLplus {
    @media only screen and (min-width: $XXL + 1) {
      @content;
    }
  }

  @else if $canvas==XSneg {
    @media only screen and (max-width: $XS) {
      @content;
    }
  }
  @else if $canvas==Sneg {
    @media only screen and (max-width: $S) {
      @content;
    }
  }
  @else if $canvas==SMneg {
    @media only screen and (max-width: $SM) {
      @content;
    }
  }
  @else if $canvas==Mneg {
    @media only screen and (max-width: $M) {
      @content;
    }
  }
  @else if $canvas==Lneg {
    @media only screen and (max-width: $L) {
      @content;
    }
  }
  @else if $canvas==XLneg {
    @media only screen and (max-width: $XL) {
      @content;
    }
  }
  @else if $canvas==QXLneg {
    @media only screen and (max-width: $QXL) {
      @content;
    }
  }
  @else if $canvas==QSXLneg {
    @media only screen and (max-width: $QSXL) {
      @content;
    }
  }
  @else if $canvas==StoL {
    @media only screen and (min-width: $S) and (max-width: $L - 1) {
      @content;
    }
  }
}