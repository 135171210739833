.main-header {
    //outline: 1px dotted black;
    //* {
    //    outline: 1px dotted black;
    //}
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    height: 135px;
    padding: 45px 0 10px;
    background-color: $white;

    .logo {
        float: left;
        max-width: 180px;
        height: 80px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    @include MQ(Lneg) {
        height: 70px;
        padding: 0;

        .inner {
            padding: 0 20px;
        }

        .logo {
            max-width: 130px;
            height: 70px;
        }
    }

    .main-menu {
        float: right;

        @include MQ(Lneg) {
            display: none;
        }

        ul {

            li {
                display: inline-block;

                a {
                    display: block;
                    position: relative;
                    text-transform: uppercase;
                    font-weight: bold;
                    padding: 0 15px;
                    height: 80px;
                    line-height: 80px;
                    color: $black;
                    transition: color .3s ease-out;

                    &:after {
                        content: '';
                        position: absolute;
                        height: 2px;
                        background-color: $yellow;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        opacity: 0;
                        transition: opacity .3s ease-out;
                    }

                    &:hover, &.active {
                        color: $yellow;

                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &.mobile {
        @include MQ(Lneg) {
            position: fixed;
            top: 0;
            right: 0;
            height: auto;
            background: transparent;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 70px;
                background-color: $white;
                z-index: -1;
            }

            .main-menu {
                position: absolute;
                right: 20px;
                display: block;
                width: 25%;
                min-width: 160px;
                margin-top: 18px;
                background-color: $white;

                ul {
                    padding-top: 40px;
                    border: 2px solid $yellow;

                    li {
                        display: block;

                        a {
                            height: 40px;
                            line-height: 40px;

                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }




    //&.mobile { // menu full screen
    //    position: fixed;
    //    top: 0;
    //    right: 0;
    //    bottom: 0;
    //    left: 0;
    //    height: auto;
    //    overflow: hidden;
    //
    //    .inner {
    //        position: absolute;
    //        width: 100%;
    //        height: 100%;
    //    }
    //
    //    .main-menu {
    //        display: flex;
    //        align-items: center;
    //        position: absolute;
    //        top: 0;
    //        right: 0;
    //        bottom: 0;
    //        left: 0;
    //
    //        ul {
    //            width: 100%;
    //
    //            li {
    //                display: block;
    //
    //                a {
    //                    text-align: center;
    //                    width: 30%;
    //                    min-width: 100px;
    //                    margin: 0 auto;
    //                    height: 55px;
    //                    line-height: 55px;
    //                }
    //            }
    //        }
    //    }
    //}
}