button, .btn {
  display: block;
  text-align: center;
  font-weight: bold;
  transition: background .3s ease-out, color .3s ease-out;

  @include MQ(Mplus) {
    width: 15vw;
    height: 4.16vw;
    line-height: 3.75vw;
    font-size: 2.3vw;
  }

  @include MQ(Lplus) {
    width: 180px;
    height: 50px;
    line-height: 45px;
    text-align: center;
    font-size: 2.3rem;
  }

  @include MQ(Mneg) {
    width: 130px;
    height: 30px;
    line-height: 25px;
    text-align: center;
    font-size: 1.6rem;
  }

  &.yellow {
    border: 2px solid $yellow;
    color: $yellow;

    &:not(.ba-element):hover {
      color: $white;
      background-color: $yellow;
    }
  }

  &.crimson {
    border: 2px solid $crimson;
    color: $crimson;

    &:not(.ba-element):hover {
      color: $white;
      background-color: $crimson;
    }
  }
}

.icon { // just an example
  color: yellow;
  display: block;
  width: 40px;
  height: 40px;
}

.separator-container {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  background-color: $yellow;
  margin: 0 auto;
  float: none;
  height: 30px;

  .separator {
    width: 180px;
    margin: auto;
    border-left: 90px solid transparent;
    border-right: 90px solid transparent;
    border-top: 30px solid $white;
  }
}

#burger {
  display: none;
  position: absolute;
  top: 18px;
  right: 20px;
  width: 35px;
  height: 35px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  border: 2px solid $yellow;

  span {
    display: block;
    position: absolute;
    height: 2px;
    left: 12%;
    right: 12%;
    width: 76%;
    background: $yellow;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 8px;
    }

    &:nth-child(2), &:nth-child(3) {
      top: 15px;
    }

    &:nth-child(4) {
      top: 22px;
    }
  }

  .mobile & {

    span {

      &:nth-child(1) {
        top: 15px;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 15px;
        width: 0;
        left: 50%;
      }
    }
  }

  @include MQ(Lneg) {
    display: block;
  }
}

.cookies-alert {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($crimson, 0.9);
    color: $white;
    text-align: center;
    padding: 10px 0;
    display: none;

    button {
        display: inline-block;
        width: 100px;
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.8rem;
        border: 2px solid $white;
        color: $white;
        cursor: pointer;
        margin: 5px 20px 5px 20px;

        &:hover {
            color: $crimson;
            background-color: $white;
        }
    }


    p {
        display: inline-block;
        font-size: 1.5rem;
        line-height: 1.8rem;
        text-align: center;
        margin: 5px;

        a {
            color: $white;
            text-decoration: underline;
        }
    }

}

.rodo-description {
  h2,h3,h4 {
    font-size: 18px;
  }

  p, * {
    font-size: 14px;
  }
}

.modal-rodo-outer {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.6);
  z-index: 99999;

}

.show-rodo .modal-rodo-outer {
  display: block;
}

.modal-rodo-inner {
  background-color: #dedede;
  width: 50%;
  max-width: 800px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 35px 10px 35px 25px;
  color: #000;
  position: absolute;
  top: calc(50% - 25px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-rodo-inner .rodo-description {
  overflow-y: auto;
  max-height: calc(70vh - 50px);
}

.modal-rodo-inner h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.modal-rodo-inner ul {
  list-style-type: none;
}

.modal-rodo-inner p {
  margin: 10px 0;
}

.modal-rodo-inner .part-2 {
  display: none;
}

.modal-rodo-inner .buttons-wrapper {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
}

.modal-rodo-inner .button {
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  color: #fff;
}

.modal-rodo-inner .button.rodo-later {
  float: left;
  background-color: #6b6667;
}

.modal-rodo-inner .button.rodo-agree {
  float: right;
  background-color: #0099d7;
}

.modal-rodo-inner #show-more {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
}

@media only screen and (max-width: 900px) {
  .modal-rodo-inner {
      width: 80%;
  }
}

@media only screen and (max-width: 570px) {
  .modal-rodo-inner {
      width: 100%;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
  }
}