$max-width: 1560px;
$padding-indications: 24px;
$padding-el: 20px 0;

$gutter1: 0.83333%;

$yellow: #f7a600;
$yellow-border: #f7a805;
$crimson: #912d20;

$white: #ffffff;
$black: #000000;

$grey: #999999;
$grey-back: #efefef;


// breakpoints
$XS: 320px;
$S: 480px;
$SM: 640px;
$M: 768px;
$L: 992px;
$XL: 1200px;
$QXL: 1400px;
$QSXL: 1600px;
$XXL: 1920px;