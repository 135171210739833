.synergy {

    position: relative;

    @include MQ(Mneg) {
        margin-top: 35px;

        .image, .text {
            width: span(10);
            margin: 0 auto;
            float: none;
        }

        .text .middle h2 {
            text-align: center;

        }

        .text .middle p {
            text-align: center;
        }

        figure {
            width: 80%;
            margin: 0 auto 20px;

            figcaption {
                font-size: 5vw;
                line-height: 5.6vw;
                height: 16.8vw;
            }
        }
    }

    @include MQ(Mplus) {

        p {
            font-size: 2.3vw
        }

        .text {
            display: flex;
            align-items: center;
        }

        figure {

            figcaption {
                font-size: 3vw;
                line-height: 3.6vw;
                height: 10.8vw;
            }
        }
    }

    .synergy-action {
        position: relative;

        @include MQ(Mplus) {

            .image, .text {
                height: 34.16vw;
            }
        }

        @include MQ(XLplus) {
            .image, .text {
                height: 410px;
            }

            p {
                font-size: 2.3rem;
            }
        }

        h2 {
            color: $crimson;
        }

        figure.synergy-animation {
            position: relative;
            height: 100%;

            @include MQ(Mneg) {
                max-width: 470px;
                width: 70%;
            }

            img {
                position: absolute;
                opacity: 0;
                width: 90%;
            }

            @keyframes animateTop {
                0% {
                    transform: scale(0.8) translateY(-100px);
                }
                50%, 100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            @keyframes animateBottom {
                0% {
                    transform: scale(0.8) translateY(100px);
                }
                50%, 100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            @keyframes animateCircle {

                50% {
                    transform: scale(1.1);
                    opacity: 1;
                }
                100% {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            @keyframes animateInner {

                50% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            &.animation {

                .top {
                    animation: animateTop 1s ease 0s 1 forwards;
                }

                .bottom {
                    animation: animateBottom 1s ease 0s 1 forwards;
                }

                .circle {
                    animation: animateCircle 1s ease 0s 1 forwards;
                }

                .wave {
                    animation: animateInner 1s ease 0s 1 forwards;
                }
            }

            .top {
                //transform: scale(0.8) translateY(-100px)
            }

            .bottom {
               // transform: scale(0.8) translateY(100px);
            }

            .circle {
                opacity: 0;
                transform: scale(0);
                z-index: 10;
            }

            .wave {
                opacity: 0;
                z-index: 5;
                @include MQ(Mneg) {
                    position: static;
                }
            }

            //figcaption {
            //    z-index: 20;
            //    opacity: 0;
            //    position: absolute;
            //    top: 0;
            //    right: 0;
            //    bottom: 0;
            //    left: 0;
            //    margin-top: auto;
            //    margin-bottom: auto;
            //    text-align: center;
            //    color: $crimson;
            //    font-weight: bold;
            //
            //    @include MQ(XLplus) {
            //        font-size: 34px;
            //        line-height: 40px;
            //        height: 120px;
            //    }
            //}
        }
    }

    .what-is {
        position: relative;
        padding-top: 0;

        @include MQ(Mplus) {

            .image, .text {
                height: 52vw;
                display: flex;
                align-items: center;
            }
        }

        @include MQ(XLplus) {
            .image, .text {
                height: 450px;
            }

            p {
                font-size: 2.3rem;
            }
        }

        h2 {
            color: $yellow;
        }

        figure {
            margin: 0 auto;
        }

        .separator-container {
            background-color: $crimson;
            bottom: 0;

            .separator {
                border-top: 30px solid $white;
            }
        }

        .image {

            img {
                transition: .4s transform ease-out, .4s opacity ease-out;
                transform: scale(0.3);
                opacity: 0;
            }

            &.animation {

                img {
                    transform: rotate(270deg) scale(1);
                    opacity: 1;
                }
            }
        }
    }

    & *{
        // outline: 1px red dotted;

    }
}
