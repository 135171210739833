.intro {
    position: relative;
    //outline: 1px dotted black;
    //* {
    //    outline: 1px dotted black;
    //}

    @keyframes fadeInDown {
        from {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            transform: none;
        }
    }

    > .inner {
        border-bottom: 2px solid $yellow;
        padding-top: 185px;
        padding-bottom: 130px;
        margin-bottom: 30px;
    }


    @include MQ(Mplus) {
        .intro-image, .intro-text {
            height: 28.33vw;
        }

        .intro-image {
            padding-right: span(1);
        }

        .packshot {
            width: 38.33vw;
        }
    }

    @include MQ(XLplus) {
        .intro-image, .intro-text {
            height: 340px;
        }

        .packshot {
            width: 460px;
        }
    }

    @include MQ(Mneg) {

        > .inner {
            padding: 100px 0 35px;
        }

        .intro-image {
            width: span(10);
            margin: 0 auto;
            float: none;

            .wrapper {
                width: 100%;
            }
        }

        .intro-text {
            display: block;
            width: span(10);
            margin: 0 auto;
            float: none;

            .wrapper {
                width: 100%;
            }
        }

        .packshot {
            width: 85%;
            margin-right: -10%;
        }
    }

    .intro-image {
        position: relative;
    }

    .packshot {
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0;

        &.animation {
            animation-name: fadeInDown;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }
    }

    .intro-text {
        display: flex;
        align-items: flex-end;

        .wrapper {

            h1 {
                color: $yellow;

                strong {
                    color: $crimson;
                    display: block;
                }

                @include MQ(Mneg) {
                    font-size: 6vw;
                    line-height: 7.5vw;
                }
            }

            p {

                @include MQ(Mplus) {
                    font-size: 2.3vw;
                }

                @include MQ(XLplus) {
                    font-size: 2.6rem;
                }
            }
        }
    }

    .separator-line {
        position: absolute;
        bottom: -28px;
        left: 0;
        right: 0;
        width: 180px;
        height: 30px;
        margin: auto;
        background-color: $white;

        &:before, &:after {
            content: '';
            position: absolute;
            width: 95px;
            height: 2px;
            background-color: $yellow;
        }

        &:before {
            transform: rotate(18deg);
            transform-origin: 0 0;
            left: 0;
        }

        &:after {
            transform: rotate(-18deg);
            transform-origin: 100% 0;
            right: 0;
        }
    }
}