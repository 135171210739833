footer.main-footer {
    background-color: $white;
    position: relative;

    .inner {
        margin: 0 auto;
        padding-top: 0;


        h2,h4 {
            color: $crimson;
            text-transform: uppercase;
            // padding-bottom: 26px;
        }
    
        h2 {
            font-size: 5.3rem;
            margin-bottom: 25px;
            @include MQ(Lneg) {
                text-align: center;
            }
        }
    
        a {
            color: $crimson;
    
            &.form-link {
                text-transform: uppercase;
                font-weight: bold; 
            }
        }

        div.accordion {
            background-color: $white;
            z-index: 1;
            padding: 15px 0;
            border-bottom: $crimson 2px solid;
            cursor: pointer;
            position: relative;

            @include clear-outline;

            p {
                margin: 0;
                margin-right: 14px;
            }

            &::after {
                background-image: url('../img/arrow-down.png');
                background-size: 9px 8px;
                content: "";
                width: 9px;
                height: 8px;
                position: absolute;
                right: 0;
                bottom: calc(50% - 6px);
                display: inline-block;
            }

            &:nth-child(2) {
                padding-top: 0;

                &::after {
                    bottom: 50%;
                }
            }
        }

        div.details {
            background-color: $crimson;
            // display: none;
            box-sizing: content-box;
            height: 0px;
            padding: 0 15px;
            transition: all 0.26s ease-in;
            z-index: 1;
            position: relative;
            overflow: hidden;

            p {
                color: $white;
                margin: 0;
            }
        }

        div.accordion.clicked+div.details {
            padding: 15px;
        }
    
        strong {
            font-weight: bold;
        }

        &.inner-columns {
            margin-top: 25px;
            width: span(10);
            display: flex;
            align-items:stretch;
            @include MQ(Lneg) {
                flex-direction: column;
            }

            .first-col {
                position: relative;
                height: 250px;

                .inner-col {
                    position: absolute;
                    margin-right: 10px;
                    height: 250px;
                    padding-right: 15px;
                    
                    @include MQ(Lneg) {
                        width: calc(100% - 10px);
                    }
                }

                @include MQ(Lneg) {
                    overflow-y: visible;
                    height: auto;

                    .inner-col {
                        position: static;
                    }
                }
            }
        }

        &.drug-info {
            padding-top: 30px; 
            border-top: 2px $black solid;

            p {
                font-size: 1.5rem;
            }

            .essylimar-info {
                vertical-align: top;
                display: inline-block;

                @include MQ(Lneg) {
                    width: 100%;
                }
                // width: 70%;

                .logo {
                    padding-right: 24px;
                    vertical-align: top;
                    display: inline-block;
                    // float: left;
                    max-width: 180px;
                    height: 80px;
                    // display: flex;
                    // align-items: center;
                }

                p {
                    display: inline-block;
                    vertical-align: top;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            p.copyright {
                vertical-align: top;
                display: inline-block;
                text-align: right;

                @include MQ(Lneg) {
                    width: 100%;
                    text-align: left;
                }
            }
        }

        .two-column {
            padding-top: 70px;

            @include MQ(Mneg) {
                padding-top: 35px;
            }
        }

        .two-column:nth-child(1) {
            padding-bottom: 35px;
        }
    }

    .warn-info {
        background-color: $grey-back;
        padding-top: 35px;
        padding-bottom: 35px;

        p {
            color: $grey;
            font-size: 1.8rem;
        }
    }

    .policy-file {
        font-size: 1.8rem;

        &:hover {
            text-decoration: underline;
        }
    }
}