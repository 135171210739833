$susy: (
        columns: 12,
        gutters: 1/4,
        container-position: center,
        gutter-position: inside,
        global-box-sizing: border-box
);

.inner {
    @include container();
    background-clip: border-box;
    max-width: 1200px;
    padding: 0 12px;
}

.intro .intro-image {
    @include span(7 of 12);

    @include MQ(Mneg) {
        @include span(12 of 12);
    }
}

.intro .intro-text {
    @include span(5 of 12 last);

    @include MQ(Mneg) {
        @include span(12 of 12);
    }
}

.indications {

    .frame {
        @include span(12 of 12);
    }

    li:first-child {
        @include span(12 of 12);
        padding-left: 0;
        padding-right: 0;
    }

    .two-column {
        @include span(6 of 12);
        padding-left: gutter(6);
        padding-right: gutter(6);

        @include MQ(Mneg) {
            @include span(12 of 12);
        }

        &.clear{
            clear: both;
        }
    }

    .separator-container{
        @include span(12 of 12);
        max-width: 1200px;
        padding: 0 12px;
    }
}

.synergy .synergy-action .image {
    @include span(5 of 12);

    @include MQ(Mneg) {
        @include span(12 of 12);
    }

    //padding-left: gutter(8);
    //padding-right: gutter(8);
}

.synergy .synergy-action .text {
    @include span(6 of 12 last);

    @include MQ(Mneg) {
        @include span(12 of 12);
    }

    //padding-left: gutter(8);
    //padding-right: gutter(8);
}

.synergy .what-is .text {
    @include span(6 of 12);

    @include MQ(Mneg) {
        @include span(12 of 12);
    }

    //padding-left: gutter(8);
    //padding-right: gutter(8);
}

.synergy .what-is .image {
    @include span(5 of 12 last);

    @include MQ(Mneg) {
        @include span(12 of 12);
    }

    //padding-left: gutter(8);
    //padding-right: gutter(8);
}

.ingredients {

    .two-column {
        @include span(6 of 12);
        padding-left: gutter(6);
        padding-right: gutter(6);

        @include MQ(Mneg) {
            @include span(12 of 12);
        }

        &.clear{
            clear: both;
        }
    }

    .btn-container{
        @include span(12 of 12);
    }

}

.effectiveness {
    
    .effects {
        @include span(12 of 12);
        display: inline-block;
        float: none;
        
        p {
            padding-left: gutter(2);
            padding-right: gutter(2);

            @include MQ(Lneg) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        @include MQ(SMneg) {
            @include span(12 of 12);
        }
    }

    .ba-element {
        @include span(1 of 12);

        @include MQ(Lneg) {
            @include span(2 of 12);
        }

        &.line {
            @include span(10 of 12);

            @include MQ(Lneg) {
                @include span(8 of 12);
            }
        }
    }

    .before-after, .explain{
        @include span(12 of 12);

        @include MQ(Lneg) {
            width: span(10);
            clear: left;
            float: none;
        }
            
        li {
            @include span(3 of 12);
            padding-left: gutter(6);
            padding-right: gutter(6);
            
            @include MQ(Lneg) {
                @include span(6 of 12);
            }
        }
    }

    .container .btn-container #border {
        @include span(12 of 12);
    }
}

.main-footer {

    .two-column {
        @include span(6 of 12);

        @include MQ(Lneg) {
            @include span(12 of 12);
        }

        &:nth-child(1) {
            padding-right: gutter(8);
        }

        &:nth-child(2) {
            padding-left: gutter(8);
        }
    }

    .inner {
        
        .essylimar-info {
            @include span(8 of 12);
        }

        p.copyright {
            @include span(4 of 12);
        }
    }
}