$ps-theme-default: ( border-radius: $ps-border-radius,
rail-default-opacity: $ps-rail-default-opacity,
rail-container-hover-opacity: $ps-rail-container-hover-opacity,
rail-hover-opacity: $ps-rail-hover-opacity,
bar-bg: $ps-bar-bg,
bar-container-hover-bg: $ps-bar-container-hover-bg,
bar-hover-bg: $ps-bar-hover-bg,
rail-hover-bg: $ps-rail-hover-bg,
scrollbar-x-rail-bottom: $ps-scrollbar-x-rail-bottom,
scrollbar-x-rail-height: $ps-scrollbar-x-rail-height,
scrollbar-x-bottom: $ps-scrollbar-x-bottom,
scrollbar-x-height: $ps-scrollbar-x-height,
scrollbar-x-hover-height: $ps-scrollbar-x-hover-height,
scrollbar-y-rail-right: $ps-scrollbar-y-rail-right,
scrollbar-y-rail-width: $ps-scrollbar-y-rail-width,
scrollbar-y-right: $ps-scrollbar-y-right,
scrollbar-y-width: $ps-scrollbar-y-width,
scrollbar-y-hover-width: $ps-scrollbar-y-hover-width,
);

// Default theme
.ps {
    @include ps-container($ps-theme-default);
}